.offer {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  background-color: $c-white;
  flex-direction: column;
  margin-top: 70px;
  &__figure {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(300px, 100%);
    background-color: $c-silver;
    position: relative;
  }
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(70%);
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    position: absolute;
  }
  &__info {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-black);
    width: 100%;
    margin-bottom: 100px;
  }
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 1200px);
    flex-direction: column;
    margin: 100px 0;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    gap: 100px;
  }
  &__container,
  &__containerTwo {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 40px;
    padding: 20px;
    width: 100%;
    position: relative;
    border: 1px solid $c-silver;
  }
  &__containerTwo {
    flex-direction: row-reverse;
  }
  &__photo {
    @include Shape(400px, 400px);
    background-color: $c-silver;
    position: relative;
    z-index: 1;
    object-fit: cover;
  }
  &__descriptionBox {
    @include Flex();
    gap: 20px;
    flex-direction: column;
  }
  &__name {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-black);
  }
  &__text {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    line-height: 27px;
  }
  &__button,
  &__buttonTwo {
    @include Position(absolute, initial, 20px, initial, 20px);
    @include Font($font-l, initial, not-allowed, $f-Raleway, $c-white);
    background-color: $c-baliHai;
    width: 200px;
    padding: 15px 0;
    text-decoration: none;
    border: none;
  }
  &__buttonTwo {
    @include Position(absolute, 20px, initial, initial, 20px);
  }
  &__icon {
    @include Shape(200px, 200px);
    @include Position(absolute, -100px, initial, initial, -100px);
    z-index: 1;
  }
  &__infoTwo {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-black);
    margin-top: 100px;
    margin-bottom: 10px;
    font-weight: 1000;
  }
  &__list {
    @include Flex();
    @include JustifyContent(center);
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }
  &__item {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    padding-left: 20px;
    text-indent: -7px;
  }
}

@media only screen and (max-width: 1250px) {
  .offer {
    &__main {
      width: 100%;
    }
    &__list {
      width: 750px;
    }
    &__info {
      text-align: center;
      width: 500px;
    }
    &__photo {
      @include Shape(550px, 500px);
    }
    &__container,
    &__containerTwo {
      width: 500px;
      flex-direction: column;
    }
    &__button,
    &__buttonTwo {
      @include Position(relative, initial, initial, initial, initial);
    }
    &__icon {
      @include Shape(100px, 100px);
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 800px) {
  .offer {
    &__main {
      margin: 50px 0;
    }
    &__info {
      font-size: $font-s;
      width: 300px;
      margin-bottom: 50px;
    }
    &__name {
      font-size: $font-s;
    }
    &__title {
      font-size: $font-x;
    }
    &__figure {
      height: 150px;
    }
    &__photo {
      @include Shape(300px, 250px);
    }
    &__container,
    &__containerTwo {
      width: 250px;
    }
    &__icon {
      display: none;
    }
    &__wrapper {
      gap: 50px;
    }
    &__infoTwo,
    &__item,
    &__text {
      font-size: $font-s;
    }
    &__button {
      font-size: $font-s;
      width: 150px;
      padding: 10px 0;
    }
    &__info,
    &__list {
      width: 300px;
    }
    &__infoTwo {
      margin-top: 50px;
    }
  }
}
