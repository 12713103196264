.contact {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  background-color: $c-white;
  flex-direction: column;
  margin-top: 70px;
  &__figure {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(300px, 100%);
    background-color: $c-silver;
    position: relative;
    overflow: hidden;
  }
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(70%);
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    position: absolute;
  }
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 1200px);
    flex-direction: column;
    margin: 100px 0;
    position: relative;
  }
  &__stars {
    @include Position(absolute, 130px, initial, initial, 0);
    @include Shape(150px, 150px);
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(space-between);
    @include AlignItems(center);
    width: 100%;
    position: relative;
  }
  &__info {
    @include Flex();
    @include JustifyContent(center);
    width: 300px;
    flex-direction: column;
    position: relative;
  }
  &__form {
    @include Flex();
    @include JustifyContent(center);
    width: 600px;
    gap: 20px;
    flex-direction: column;
  }
  &__name {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    line-height: 27px;
  }
  &__socialMedia {
    @include Flex();
    width: 100%;
    gap: 20px;
    margin-top: 20px;
  }
  &__icon {
    @include Shape(30px, 30px);
  }
  &__box {
    @include Flex();
    gap: 10px;
    width: 100%;
    flex-direction: column;
  }
  &__label,
  &__value,
  &__valueTwo,
  &__valueArea,
  &__valueTwoArea {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    width: 100%;
  }
  &__value,
  &__valueArea {
    border: 1px solid $c-silver;
    padding: 5px;
  }
  &__valueTwo,
  &__valueTwoArea {
    border: 1px solid $c-monza;
    padding: 5px;
  }
  &__valueArea,
  &__valueTwoArea {
    height: 200px;
  }
  &__buttonWrapper {
    @include Flex();
    width: 100%;
  }
  &__button,
  &__buttonOff {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-white);
    background-color: $c-silver;
    width: 200px;
    padding: 15px 0;
    text-decoration: none;
    border: none;
  }
  &__buttonOff {
    cursor: not-allowed;
  }
  &__button:hover {
    background-color: $c-silver;
  }
}

@media only screen and (max-width: 1250px) {
  .contact {
    &__stars {
      display: none;
    }
    &__main {
      width: 100%;
    }
    &__wrapper {
      @include JustifyContent(center);
      flex-direction: column-reverse;
      gap: 70px;
    }
    &__info {
      @include AlignItems(center);
    }
    &__buttonWrapper {
      @include AlignItems(center);
      @include JustifyContent(center);
    }
  }
}

@media only screen and (max-width: 800px) {
  .contact {
    &__main {
      margin: 50px 0;
    }
    &__title {
      font-size: $font-x;
    }
    &__figure {
      height: 150px;
    }
    &__form {
      width: 250px;
    }
    &__button,
    &__buttonOff {
      font-size: $font-s;
      width: 150px;
      padding: 10px 0;
    }
    &__name,
    &__label,
    &__value,
    &__valueTwo {
      font-size: $font-s;
    }
    &__socialMedia {
      @include JustifyContent(center);
      @include AlignItems(center);
    }
    &__icon {
      @include Shape(25px, 25px);
    }
    &__wrapper {
      gap: 30px;
    }
  }
}
