.navigation {
  @include Flex();
  @include Shape(70px, 100%);
  @include JustifyContent(space-between);
  @include AlignItems(center);
  @include Position(fixed, 0, initial, 0, initial);
  z-index: 1000;
  box-sizing: border-box;
  padding: 10px;
  background-color: $c-silver;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.778);
  &__logo {
    @include Shape(25px, 160px);
  }
  &__link {
    @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
    background-color: $c-black;
    padding: 10px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .navigation {
    &__logo {
      @include Shape(15px, 100px);
    }
  }
}
