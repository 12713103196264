.footer {
  @include Flex();
  @include AlignItems(center);
  @include JustifyContent(center);
  @include Shape(200px, 100%);
  background-color: $c-alto;
  &__main {
    @include Flex();
    @include AlignItems(center);
    @include JustifyContent(center);
    @include Shape(200px, 1200px);
  }
  &__wrapper,
  &__wrapperTwo {
    @include Flex();
    @include AlignItems(center);
    @include JustifyContent(center);
    @include Shape(100%, 50%);
  }
  &__wrapper {
    width: 35%;
  }
  &__wrapperTwo {
    @include JustifyContent(flex-end);
    width: 65%;
    text-align: right;
  }
  &__text {
    @include Font($font-l, initial, initial, $f-Raleway, $c-white);
  }
  &__box,
  &__boxTwo {
    @include Flex();
    width: 100%;
    flex-direction: column;
  }
  &__boxTwo {
    @include JustifyContent(flex-end);
    gap: 20px;
    flex-direction: row;
  }
  &__link {
    @include Shape(30px, 30px);
  }
  &__icon {
    @include Shape(100%, 100%);
  }
}

@media only screen and (max-width: 1250px) {
  .footer {
    height: 100%;
    &__main {
      height: 100%;
      margin: 50px 0;
      flex-direction: column;
      gap: 50px;
    }
    &__wrapper,
    &__wrapperTwo {
      @include JustifyContent(center);
      flex-direction: column;
      width: 100%;
    }
    &__box,
    &__boxTwo {
      @include AlignItems(center);
      @include JustifyContent(center);
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    &__title {
      font-size: $font-x;
    }
    &__text {
      font-size: $font-s;
      text-align: center;
      width: 250px;
    }
    &__link {
      @include Shape(25px, 25px);
    }
  }
}