.hamburger {
  display: none;
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Shape(25px, 25px);
  &__button {
    @include Shape(25px, 25px);
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  &__icon {
    @include Shape(100%, 100%);
  }
}

@media only screen and (max-width: 1250px) {
  .hamburger {
    @include Flex();
  }
}
