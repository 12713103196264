.aboutUs {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  background-color: $c-white;
  flex-direction: column;
  margin-top: 70px;
  &__figure {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(300px, 100%);
    background-color: $c-silver;
    position: relative;
  }
  &__figureTwo {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(500px, 600px);
    position: relative;
    margin-bottom: 40px;
  }
  &__figureThree {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(500px, 600px);
    position: relative;
  }
  &__imageThree {
    @include Shape(100%, 100%);
    background-color: $c-silver;
    object-fit: cover;
  }
  &__imageTwo {
    @include Shape(100%, 100%);
    background-color: $c-silver;
    object-fit: cover;
  }
  &__icon {
    @include Position(absolute, initial, -15px, -60px, initial);
    @include Shape(100px, 100px);
    transform: rotate(270deg)
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    position: relative;
    &--color {
      color: $c-black;
    }
  }
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    object-fit: cover;
    object-position: 50% 40%;
    filter: brightness(70%);
  }
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    width: 1200px;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(space-between);
    width: 100%;
    margin: 100px 0;
  }
  &__container {
    @include Flex();
    flex-direction: column;
  }
  &__box {
    @include Flex();
    flex-direction: column;
    gap: 20px;
  }
  &__description {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    line-height: 27px;
    border-radius: 10px;
    width: 400px;
    text-align: left;
    backdrop-filter: blur(5px);
  }
}

@media only screen and (max-width: 1250px) {
  .aboutUs {
    &__main {
      width: 100%;
    }
    &__box {
      margin-top: 20px;
    }
    &__wrapper {
      @include JustifyContent(center);
      @include AlignItems(center);
      flex-direction: column;
    }
    &__figureTwo,
    &__figureThree {
      @include Shape(300px, 400px);
    }
    &__icon {
      @include Position(absolute, initial, -15px, -30px, initial);
      @include Shape(50px, 50px);
    }
    &__description {
      text-align: center;
    }
    &__title {
      text-align: center;
    }
    &__container {
      @include Flex();
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 800px) {
  .aboutUs {
    &__wrapper {
      margin: 50px 0;
    }
    &__title {
      font-size: $font-x;
    }
    &__description {
      font-size: $font-s;
      text-align: center;
      width: 250px;
    }
    &__figure {
      height: 100%;
      padding: 50px 0;
    }
    &__figureTwo,
    &__figureThree {
      @include Shape(150px, 250px);
    }
    &__icon {
      @include Position(absolute, initial, -10px, -20px, initial);
      @include Shape(30px, 30px);
    }
  }
}