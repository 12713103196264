.priceList {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  background-color: $c-white;
  flex-direction: column;
  margin-top: 70px;
  &__figure {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(300px, 100%);
    background-color: $c-silver;
    position: relative;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    position: absolute;
  }
  &__nameWrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    width: 750px;
    margin-top: 100px;
  }
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    object-fit: cover;
    object-position: 50% 40%;
    filter: brightness(70%);
  }
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 1200px);
    margin: 60px 0;
    flex-direction: column;
  }
  &__container,
  &__containerTwo {
    @include Flex();
    @include JustifyContent(space-between);
    padding: 40px 0;
    width: 100%;
    border-top: 1px solid $c-silver;
  }
  &__containerTwo {
    border-top: none;
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 20px;
  }
  &__icon {
    @include Shape(100px, 100px);
    background-color: $c-silver;
    border-radius: 10px;
    object-fit: cover;
  }
  &__descriptionBox {
    @include Flex();
    @include JustifyContent(center);
    gap: 10px;
    flex-direction: column;
  }
  &__name {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-black);
    &--center {
      text-align: center;
    }
  }
  &__text {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
  }
  &__infoWrapp {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    width: 1200px;
  }
  &__info {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    text-align: center;
    width: 500px;
    margin-bottom: 100px;
  }
  &__price {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-black);
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1250px) {
  .priceList {
    &__main,
    &__infoWrapp {
      width: 100%;
    }
    &__infoWrapp {
      @include JustifyContent(center);
      @include AlignItems(center);
    }
    &__nameWrapper {
      width: 500px;
    }
    &__info {
      text-align: center;
    }
    &__info {
      text-align: center;
    }
    &__container,
    &__containerTwo {
      @include JustifyContent(center);
      @include AlignItems(center);
      flex-direction: column;
    }
    &__box {
      flex-direction: column;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .priceList {
    &__main {
      margin: 30px 0;
    }
    &__info {
      font-size: $font-s;
      margin-bottom: 50px;
      width: 300px;
    }
    &__nameWrapper {
      margin-top: 50px;
      width: 300px;
    }
    &__figure {
      height: 150px;
    }
    &__container,
    &__containerTwo {
      width: 250px;
    }
    &__name,
    &__price,
    &__text {
      font-size: $font-s;
    }
    &__icon {
      @include Shape(80px, 80px);
    }
    &__title {
      font-size: $font-x;
    }
  }
}
