.cookie {
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Position(fixed, 0, initial, initial, 0);
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.896);
  padding: 15px;
  text-align: center;
  @include Font($font-l, initial, initial, $f-arialRegular, $c-white);
  z-index: 9999;
  &__content {
    @include Font($font-l, initial, initial, $f-arialRegular, $c-white);
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    margin: 0 auto;
  }
  &__text {
    margin-bottom: 10px;
    max-width: 600px;
    color: $c-white;
  }

  &__button {
    @include Font($font-l, initial, initial, $f-arialRegular, $c-white);
    padding: 10px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #45a049;
    }
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-arialRegular, $c-white);
    text-decoration: none;
  }
  &__link:hover {
    color: rgb(82, 82, 246);
  }
}

@media only screen and (max-width: 800px) {
  .cookie {
    font-size: $font-s;
    &__button {
      font-size: $font-s;
    }
    &__link {
      font-size: $font-s;
    }
    &__content {
      font-size: $font-s;
    }
  }
}
