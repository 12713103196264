.projects {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  background-color: $c-white;
  flex-direction: column;
  margin-top: 70px;
  &__figure {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(300px, 100%);
    background-color: $c-silver;
    position: relative;
  }
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(70%);
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    position: absolute;
  }
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 1200px);
    margin: 100px 0;
    flex-direction: column;
  }
  &__container {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(flex-start);
    overflow: visible;
  }
  &__button {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-black);
    background-color: transparent;
    width: 100%;
    border: none;
    text-decoration: none;
    text-align: left;
  }
  &__button:hover {
    color: $c-silver;
  }
  &__options {
    @include Flex();
    gap: 20px;
    width: 200px;
    flex-direction: column;
    position: sticky;
    top: 70px;
    z-index: 10;
    background-color: $c-white;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 100px;
    flex-direction: column;
  }
  &__wrapperChild {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    gap: 20px;
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 20px;
  }
  &__wrapperPhoto {
    @include Shape(500px, 500px);
    background-color: $c-silver;
    overflow: hidden;
  }
  &__photo {
    @include Shape(100%, 100%);
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  &__wrapperPhoto:hover .projects__photo {
    transform: scale(1.2);
  }
  &__descriptionWrapp {
    margin-top: 40px;
    width: 1020px;
  }
  &__descriptionApp {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    width: 100%;
    &--line {
      text-decoration: underline;
    }
  }
  &__list {
    @include Flex();
    @include JustifyContent(center);
    flex-direction: column;
    padding: 0;
    width: 1020px;
    gap: 10px;
  }
  &__item {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    padding-left: 20px;
    text-indent: -7px;
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-bahamaBlue);
    transition: color 200ms ease-in-out;
    position: relative;
    top: 20px;
  }
  &__link:hover {
    color: $c-silver;
  }
}

@media only screen and (max-width: 1250px) {
  .projects {
    &__descriptionWrapp,
    &__list {
      width: 400px;
    }
    &__main {
      width: 100%;
    }
    &__box {
      flex-direction: column;
    }
    &__wrapperPhoto {
      @include Shape(400px, 400px);
    }
  }
}

@media only screen and (max-width: 800px) {
  .projects {
    &__main {
      margin: 50px 0;
    }
    &__figure {
      height: 150px;
    }
    &__title {
      font-size: $font-x;
    }
    &__options {
      @include JustifyContent(center);
      @include AlignItems(center);
      width: 100px;
    }
    &__wrapperPhoto {
      @include Shape(200px, 200px);
    }
    &__button,
    &__item,
    &__descriptionApp,
    &__link  {
      font-size: $font-s;
    }
    &__link  {
      font-size: $font-s - 0.8px;
    }
    &__descriptionApp,
    &__descriptionWrapp,
    &__list {
      width: 200px;
    }
  }
}
