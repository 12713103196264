.history {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.27);
    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 2;
  }
}

.history {
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Shape(100%, 100%);
  flex-direction: column;
  background-image: url("../../assets/analyze.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &__main {
    @include Flex();
    @include Shape(100%, 1200px);
    @include JustifyContent(center);
    gap: 20px;
    margin: 100px 0;
    flex-direction: column;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    width: 100%;
    font-weight: 700;
  }
  &__description {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-white);
    border-radius: 10px;
    width: 500px;
    padding: 10px;
    backdrop-filter: blur(5px);
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    flex-direction: column;
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-white);
    margin-top: 10px;
    width: 200px;
    background-color: $c-silver;
    padding: 15px 0;
    text-decoration: none;
    transition: background-color 0.5s ease-in-out;
    text-align: center;
  }
}

@media only screen and (max-width: 1250px) {
  .history {
    @include Shape(100%, 100%);
    flex-direction: column;
    &__main {
      @include Shape(100%, 100%);
      @include AlignItems(center);
      margin: 100px 0;
    }
    &__title,
    &__description {
      text-align: center;
    }
    &__desciption {
      padding: 0 20px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .history {
    &__main {
      margin: 50px 0;
    }
    &__title {
      font-size: $font-x;
    }
    &__description {
      font-size: $font-s;
      width: 250px;
    }
    &__link {
      font-size: $font-s;
      width: 150px;
      padding: 10px 0;
    }
  }
}