$c-william : #3B5B66;
$c-bismark : #4F7582;
$c-botticelli : #CEE2EA;
$-zigguart : #B5D0DA;
$c-white: #FFFFFF;
$c-black: #000000;
$c-grey : rgb(51, 51, 51);
$c-cornflower : #85CCE6;
$c-alto: #D3D3D3;
$c-silver: #C2C1C1;
$c-emerald: #5BC474;
$c-baliHai: #8893aa;
$c-bahamaBlue: #005792;
$c-monza: #D60A1B;

$f-arialRegular: 'Roboto Flex', sans-serif;
$f-Audiowide: 'Audiowide', cursive;
$f-Bungee: 'Bungee Spice', cursive;
$f-Diplomata: 'Diplomata', cursive;
$f-Raleway: 'Raleway', sans-serif;
$f-DancingScript: 'Dancing Script', cursive;

$font-full: 60px;
$font-x: 35px;
$font-standard: 22px;
$font-l: 17px;
$font-s: 13px;
