@mixin Position($position: "relative", $left: 0, $right: 0, $top: 0, $bottom: 0) {
    position: $position;
    left: $left;
    right: $right;
    top: $top;
    bottom: $bottom;
}

@mixin Flex {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
}

@mixin BorderBox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin FlexDirection($value: column) {
    @if $value ==column {
        -webkit-box-orient: $value;
        -webkit-box-direction: $value;
        -ms-flex-direction: $value;
        flex-direction: $value;
    } @else if $value ==row {
        -webkit-box-orient: $value;
        -webkit-box-direction: $value;
        -ms-flex-direction: $value;
        flex-direction: $value;
    }
}

@mixin JustifyContent($value: flex-start) {
    @if $value ==flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value ==flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value ==center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
    } @else if $value ==space-between {
        -webkit-box-pack: space-between;
        -ms-flex-pack: space-between;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin AlignItems($value: center) {
    @if $value ==flex-start {
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
    } @else if $value ==flex-end {
        -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
    } @else if $value ==center {
        -webkit-box-align: center;
        -ms-flex-align: center;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-box-align: $value;
    -ms-flex-align: $value;
    align-items: $value;
}

@mixin AlignSelf($value: center) {
    @if $value ==stretch {
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
    } @else if $value ==flex-end {
        -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
    } @else if $value ==stretch {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-box-align: $value;
    -ms-flex-align: $value;
    align-self: $value;
}

@mixin Shape($height: 0, $width: 0) {
    height: $height;
    width: $width;
}

@mixin Font($fontSize: 0, $margin: 0, $cursor: "none", $fontfamily: "none", $color: "none") {
    font-size: $fontSize;
    margin: $margin;
    cursor: $cursor;
    font-family: $fontfamily;
    color: $color;
}

@mixin BoxShadow($boxShadow: 5px 10px 8px 10px #888888) {
    -webkit-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
}
