.faq {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  flex-direction: column;
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    text-align: left;
    flex-direction: column;
    width: 1200px;
    margin: 100px 0;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-black);
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;
  }
  &__description {
    @include Font($font-standard, initial, initial, $f-Raleway, rgb(103, 103, 103));
    font-weight: 1000;
    margin-top: 20px;
  }
  &__text {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
  }
}

@media only screen and (max-width: 1250px) {
  .faq {
    &__title,
    &__description,
    &__text {
      width: 500px;
      text-align: center;
    }
    &__wrapper {
      @include AlignItems(center);
      width: 100%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .faq {
    &__title {
      font-size: $font-x;
      width: 100%;
    }
    &__description {
      font-size: $font-l;
      width: 250px;
    }
    &__text {
      font-size: $font-s;
      width: 250px;
    }
    &__wrapper {
      @include AlignItems(center);
      width: 100%;
      margin: 50px 0;
    }
    &__box {
      margin-top: 20px;
    }
  }
}