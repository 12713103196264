/* Główne style dla strony polityki prywatności */
.privacy-policy-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 120px;
  margin-bottom: 50px;
}

.privacy__title {
  @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
  position: absolute;
  text-align: center;
}

h2 {
  color: #2c3e50;
  margin-top: 20px;
  font-size: $font-standard;
  font-family: $f-Raleway;
  padding-bottom: 10px;
}

p {
  font-family: $f-Raleway;
  color: #555;
  font-size: $font-l;
  margin: 15px 0;
}

a {
  font-family: $f-Raleway;
  color: #3498db;
  text-decoration: none;
}

section {
  margin-bottom: 20px;
}

section p {
  font-family: $f-Raleway;
  margin-top: 5px;
}

@media only screen and (max-width: 800px) {
  .privacy-policy-container {
    padding: 10px;
    margin-top: 60px;
    max-width: initial;
    margin-bottom: 0px;
  }

  .privacy__title {
    font-size: $font-x;
  }

  h2 {
    font-size: $font-l;
  }

  p {
    font-size: $font-s;
  }
}
