.images {
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Shape(100%, 1200px);
  margin: 100px 0;
  gap: 30px;
  &__imageOne {
    @include Shape(600px, 570px);
    background-color: $c-silver;
    object-fit: cover;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 30px;
    flex-direction: column;
  }
  &__imageTwo {
    @include Shape(285px, 600px);
    background-color: $c-silver;
    object-fit: cover;
  }
  &__container {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 30px;
  }
  &__imageThree {
    @include Shape(285px, 285px);
    background-color: $c-silver;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1250px) {
  .images {
    @include Shape(100%, 100%);
    flex-direction: column;
    &__wrapper {
      flex-direction: column;
    }
    &__box {
      @include AlignItems(center);
      margin-top: 20px;
      padding: 0 20px;
    }
    &__description {
      text-align: center;
    }
    &__imageOne {
      @include Shape(330px, 300px);
    }
    &__imageTwo {
      @include Shape(185px, 300px);
    }
    &__imageThree {
      @include Shape(300px, 300px);
    }
    &__container {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 800px) {
  .images {
    margin: 50px 0;
    &__imageOne {
      @include Shape(280px, 250px);
    }
    &__imageTwo {
      @include Shape(135px, 250px);
    }
    &__imageThree {
      @include Shape(250px, 250px);
    }
  }
}