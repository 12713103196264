.team {
  @include Shape(100%, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  flex-direction: column;
  background-color: $c-alto;
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    margin: 100px 0;
    width: 1200px;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    text-align: center;
  }
  &__box {
    @include Flex();
    @include JustifyContent(space-between);
    @include Shape(100%, 100%);
    margin-top: 100px;
  }
  &__member {
    @include Flex();
    @include AlignItems(center);
    gap: 20px;
    flex-direction: column;
    width: 300px;
  }
  &__avatar {
    @include Shape(100px, 100px);
    background-color: $c-silver;
    object-fit: cover;
    border-radius: 50%;
  }
  &__name {
    @include Font($font-standard, initial, initial, $f-Raleway, $c-white);
    font-weight: 1000;
  }
  &__description {
    @include Font($font-l, initial, initial, $f-Raleway, $c-white);
    line-height: 27px;
    text-align: center;
  }
}

@media only screen and (max-width: 1250px) {
  .team {
    &__wrapper {
      width: 100%;
    }
    &__box {
      @include JustifyContent(center);
      @include AlignItems(center);
      flex-direction: column;
      gap: 50px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .team {
    &__wrapper {
      margin: 50px 0;
    }
    &__title {
      font-size: $font-x;
    }
    &__avatar {
      @include Shape(80px, 80px);
    }
    &__box {
      margin-top: 50px;
    }
    &__name {
      font-size: $font-l;
    }
    &__description {
      font-size: $font-s;
    }
  }
}