.aboutUsSection {
  @include Shape(initial, 1200px);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  flex-direction: column;
  &__main {
    @include Flex();
    @include JustifyContent(space-between);
    margin: 100px 0;
    width: 100%;
  }
  &__figure {
    @include Flex();
    @include AlignItems(center);
    gap: 40px;
    flex-direction: column;
    position: relative;
  }
  &__icon {
    @include Position(absolute, initial, -100px, -43px, initial);
    @include Shape(200px, 200px);
  }
  &__image {
    @include Shape(500px, 600px);
    background-color: $c-silver;
    object-fit: cover;
  }
  &__box {
    @include Flex();
    flex-direction: column;
    gap: 20px;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-black);
    width: 500px;
  }
  &__description {
    @include Font($font-l, initial, initial, $f-Raleway, $c-black);
    line-height: 27px;
    border-radius: 10px;
    width: 500px;
    text-align: left;
    backdrop-filter: blur(5px);
    &--decoration {
      color: grey;
      text-decoration: underline;
    }
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-white);
    margin-top: 20px;
    background-color: $c-silver;
    width: 200px;
    padding: 15px 0;
    text-decoration: none;
    transition: background-color 0.5s ease-in-out;
    display: inline-block;
    text-align: center;
  }
}

@media only screen and (max-width: 1250px) {
  .aboutUsSection {
    width: 100%;
    &__main {
      flex-direction: column;
    }
    &__image {
      @include Shape(200px, 300px);
      object-fit: cover;
    }
    &__title {
      text-align: center;
    }
    &__box {
      @include AlignItems(center);
      margin-top: 20px;
      padding: 0 20px;
    }
    &__description {
      text-align: center;
    }
    &__icon {
      @include Position(absolute, initial, -50px, -23px, initial);
      @include Shape(100px, 100px);
    }
  }
}

@media only screen and (max-width: 800px) {
  .aboutUsSection {
    &__main {
      margin: 50px 0;
    }
    &__image {
      @include Shape(150px, 250px);
    }
    &__icon {
      @include Position(absolute, initial, -25px, -11px, initial);
      @include Shape(50px, 50px);
    }
    &__title {
      font-size: $font-x;
      width: 100%;
    }
    &__description {
      font-size: $font-s;
      width: 250px;
    }
    &__link {
      font-size: $font-s;
      width: 150px;
      padding: 10px 0;
    }
  }
}
