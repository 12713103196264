.offerSection {
  @include Shape(initial, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  flex-direction: column;
  background-color: $c-alto;
  &__main {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(initial, 1200px);
    flex-direction: column;
    margin: 100px 0;
  }
  &__wrapper {
    @include Shape(100%, 100%);
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 100%);
    flex-direction: column;
    flex: 1;
    gap: 20px;
  }
  &__title {
    @include Font($font-full, initial, initial, $f-DancingScript, $c-white);
    width: 100%;
    font-weight: 700;
  }
  &__description {
    @include Font($font-l, initial, initial, $f-Raleway, $c-white);
    line-height: 27px;
  }
  &__linkBox {
    @include Flex();
    @include AlignItems(center);
    @include Shape(initial, 100%);
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-white);
    background-color: $c-silver;
    width: 200px;
    padding: 15px 0;
    margin-top: 20px;
    text-decoration: none;
    transition: background-color 0.5s ease-in-out;
    display: inline-block;
    text-align: center;
  }

  &__imagesBox {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 30px;
    margin-left: 100px;
    flex-direction: column;
  }
  &__imageOne {
    @include Shape(300px, 300px);
    object-fit: cover;
    background-color: $c-silver;
    filter: brightness(70%);
  }
  &__imageTwo {
    @include Shape(630px, 400px);
    margin-left: 30px;
    object-fit: cover;
    background-color: $c-silver;
    filter: brightness(70%);
  }
}

@media only screen and (max-width: 1250px) {
  .offerSection {
    &__main {
      width: 100%;
    }
    &__wrapper {
      flex-direction: column;
    }
    &__box {
      @include AlignItems(center);
      margin: 0;
    }
    &__title {
      text-align: center;
    }
    &__description {
      width: 500px;
      text-align: center;
      padding: 0 20px;
    }
    &__linkBox {
      @include JustifyContent(center);
    }
    &__imagesBox {
      margin: 0;
      margin-top: 50px;
    }
    &__imageTwo {
      margin: 30px 0 0 0;
    }
    &__imageOne,
    &__imageTwo {
      @include Shape(300px, 300px);
    }
  }
}

@media only screen and (max-width: 800px) {
  .offerSection {
    &__main {
      margin: 50px 0;
    }
    &__title {
      font-size: $font-x;
    }
    &__description {
      font-size: $font-s;
      width: 250px;
    }
    &__link {
      font-size: $font-s;
      width: 150px;
      padding: 10px 0;
    }
    &__imageOne,
    &__imageTwo {
      @include Shape(250px, 250px);
    }
  }
}
