.menu {
  @include Flex();
  @include Shape(initial, initial);
  flex-direction: column;
  position: relative;
  &__list,
  &__listTwo {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Font($font-l, initial, initial, $f-Raleway, $c-white);
    gap: 50px;
    padding: 0;
  }
  &__flags {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 15px;
    margin-top: 5px;
    margin-right: 5px;
  }
  &__item {
    cursor: pointer;
  }
  &__icon {
    @include Shape(30px, 30px);
  }
  &__link {
    @include Font($font-l, initial, pointer, $f-Raleway, $c-white);
    text-decoration: none;
  }
}

@media only screen and (max-width: 1250px) {
  .menu {
    &__list,
    &__listTwo {
      @include Position(absolute, initial, -10px, 47px, initial);
      padding: 20px 0;
      z-index: 1000;
      background-color: $c-white;
      width: 200px;
      flex-direction: column;
      gap: 20px;
    }
    &__listTwo {
      display: none;
    }
    &__link {
      color: $c-black;
    }
  }
}

@media only screen and (max-width: 800px) {
  .menu {
    &__link {
      font-size: $font-s;
    }
    &__icon {
      @include Shape(25px, 25px);
    }
  }
}
